<!-- Splash.vue -->
<template>
    <div class="splash-overlay" @click="beginExploring">
    <div class="splash-modal">
      <h1>RTI Rarity™</h1>
      <h4>What are some of the most pressing health challenges facing your local community?</h4>
      <p>
        The RTI Rarity dashboard is a tool that answers this question using advanced data science and machine learning techniques to analyze more than 200 factors affecting health and health outcomes to generate Local Index (LI) scores. Users can evaluate specific health outcomes in their communities including life expectancy, cancer mortality, and drug overdose mortality. Using these data and the LI scores, users can discover important health challenges in their community and gain valuable insights to make a difference. 
    </p>
      <button @click.stop="beginExploring">Start exploring today!</button>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    methods: {
      beginExploring() {
        // Emit an event to inform the parent component that the user wants to begin exploring.
        this.$emit("start-exploring");
      },
    },
  };
  </script>
  
  <style scoped>
/* CSS styles for the splash overlay */
.splash-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
}

/* CSS styles for the splash modal */
.splash-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 80%;
}
</style>
