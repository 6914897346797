/* eslint-disable */
<template>
<div class="container-about">
  <div class="banner-image">
    <img src="../assets/images/RarityLogo.png">
  </div>
  <div class="container-about--header">
    <p class="about-header">
      <b>Overview</b>
    </p>
    <div class="container-about--text">
      <p>
        As part of the internally funded RTI Innovation Award program, the RTI Rarity™ project aims to improve the health of the American people using the power of data science. Our offerings include a comprehensive data library of area-level measures across numerous domains and a suite of AI-generated risk scores to identify community level needs and gaps in health access and health outcomes that have been published in scientific papers and used by our clients to help determine policy directions, funding allocation needs, and community impacts that they are involved in. 
      </p>
      <div class="display-flex-award">
        <div class="award-text">
          Our project won a <a href="https://www.rti.org/announcements/rti-rarity-recognized-2022-disruptive-tech-program-award">
            2022 Disruptive Tech Program Award</a> from G2Xchange and FedHealthIT. 
        </div>
        <div class="award-image">
          <img style="max-width:300px;" src="../assets/images/Disruptive_Tech_Awards.png">
        </div>
      </div>
      <p>In 2023, we were honored with an <i>RTI Press</i> Editorial Board Award for our peer-reviewed journal article 
        about the development of our scores. Read the paper and access the technical appendix <a href="https://www.rti.org/rti-press-publication/artificially-intelligent-social-risk-adjustment-0">here</a>. 
        More information about the LI scores is available at <a href="http://rti.org/Rarity">RTI.org/Rarity</a>.
      </p>
    </div>
  </div>
  <div class="container-about--header">
    <hr>
    <br>
    <p class="about-header">
      <b>RTI Rarity's Data Library</b>
    </p>
    <div class="container-about--text">
      <p>
        Since 2019, the RTI Rarity team has curated a large data library of more than 230 metrics that influence overall 
        wellbeing and health including income and employment, education, access to healthcare, transportation, crime and justice, availability of nutritious food, 
        and housing quality, among others. Additionally, we have gathered   data on demographics, COVID-19 mortality, and policies that affect individual’s health.  
        We update our data library regularly as new data are released across our sources to ensure that it remains current and relevant. 
      </p>
      <p>We utilize data from over 40 publicly available sources including federal and state agencies as well as nonprofit and academic organizations. Some key sources 
        include the American Community Survey (ACS) and the USDA’s Food Environment Atlas. To date, our validated, research-ready data have been used on more than 50
         projects across multiple practice areas at RTI International. Licensing information is available on request: visit <a href="http://www.rti.org/rarity">RTI.org/Rarity</a>
      </p>
    </div>
  </div>
  <!-- <div class="container-about--content"> -->
    <!-- <div class="container-about--section">
      <button class="section-header" @click="expandAccordian">
        AFCARS Infant-Toddler Removals and Placement
      </button>
      <div class="section-content">
        <p>Data from the Adoption and Foster Care Analysis and Reporting System (AFCARS Foster Care) for the federal fiscal years (FFY)
            2015-2019 are reported separately at the county- and state-levels and presented in aggregate.
          Refer to the National Data Archive on Child Abuse and Neglect (NDACAN) for more information on these data
          (see www.ndacan.cornell.edu )*
        </p>
        <p>
          <b>Average annual removals per 1k, under age 5</b> reports the rate of infant-toddler removals per 1,000
          children under 5. Removals are based on those <5 years of age at the time of the removal. The
          denominator, population of children under age 5, is from the American Community Survey, 2015-2019. </p>
          <ul class="clean-list">
            <li>Range of rates per 1,000 children across U.S. Counties: 2.1 – 25.0</li>
            <li>Range of rates per 1,000 children across U.S. States: 2.1 – 19.6</li>
          </ul>
        <p>
          <b>Total percent placement with kin, under age 5</b>  reports the percent of children who were
          placed with kin during the most recent placement in the last removal, for children under age 5 at the
          time of placement.</p>
        <ul class="clean-list">
          <li>Range of values across U.S. Counties: 2.5 – 61.3</li>
          <li>Range of values across U.S. States: 7.1 – 49.5</li>
        </ul>
        <p>
          <b>Total percent placement with non-kin, under age 5</b> reports the percent of children who were
          placed with non-kin during the most recent placement in the last removal, for children under age 5 at
          the time of placement. Non-kin placement defined as one of four placement options: (1) non-kin
          pre-adoptive home, (2) non-kin, foster home, non-relative, (3) non-kin, group home, or (4) non-kin,
          Institution.
        </p>
        <ul class="clean-list">
          <li>Range of values across U.S. Counties: 23.5 – 61.3</li>
          <li>Range of values across U.S. States: 35.4 – 49.5</li>
        </ul>
        <p>
          <b>Average annual removals per 1k, ages 5-17</b> reports the rate of removals per 1,000 children aged 5-17 years.
          Removals are based on those aged 5-17 at the time of removal. The denominator, population of children aged 5-17,
          is from the American Community Survey, 2015-2019.
        </p>
        <ul class="clean-list">
          <li>Range of rates per 1,000 children across U.S. Counties: 0.8 – 15.3</li>
          <li>Range of rates per 1,000 children across U.S. States: 1.2 – 11.4</li>
        </ul>
        <p>
          <b>Percent Placement with Kin of children age 5-17</b> reports the percent of children who were
          placed with kin during the most recent placement in the last removal, for children aged 5-17 at the time
          of placement.
        </p>
        <ul class="clean-list">
          <li>Range of values across U.S. Counties: 2.9 – 50.9</li>
          <li>Range of values across U.S. States: 6.7 – 41.7</li>
        </ul>
        <p>
          <b>Total percent placement with non-kin, ages 5-17</b> reports the percent of children who were
          placed with non-kin during the most recent placement in the last removal, for children aged 5-17 at the
          time of placement. Non-kin placement defined as one of four placement options: (1) non-kin pre-adoptive
          home, (2) non-kin, foster home, non-relative, (3) non-kin, group home, or (4) non-kin, Institution.
        </p>
        <ul class="clean-list">
          <li>Range of values across U.S. Counties: 24.7 – 96.6</li>
          <li>Range of values across U.S. States: 28.3 – 88.7</li>
        </ul>
        <p>* The data used in this publication were made available by the National Data Archive on Child Abuse and
          Neglect, Cornell University, Ithaca, NY, and have been used with permission. Data from the Adoption and
          Foster Care Analysis and Reporting System (AFCARS) were originally collected by the Children's Bureau.
          Funding for the project was provided by the Children's Bureau, Administration on Children, Youth and
          Families, Administration for Children and Families, U.S. Department of Health and Human Services. The
          collector of the original data, the funder, the Archive, Cornell University and their agents or
          employees bear no responsibility for the analyses or interpretations presented here.</p>
        <p>Counties without data may have less than 1,000 children and the data cannot be provided by NDACAN, or
          the child welfare agency is located at a neighboring county and all cases are counted under the lead
          agency.</p>
        <p>The bibliographic citation for this data collection is:</p>
        <ul class="bibliography">
          <li>Children's Bureau Administration on Children, Youth and Families. (2022). Adoption and Foster Care Analysis and Reporting System (AFCARS), Foster Care 6-month periods (FY2016A-2021A) [Data set]. National Data Archive on Child Abuse and Neglect (NDACAN). <a href="https://doi.org/10.34681/afqh-np77">https://doi.org/10.34681/afqh-np77</a>
          </li>
          <li>Children's Bureau, Administration On Children, Youth And Families, Administration For Children And Families, U. S. Department Of Health And Human Services (2017). AFCARS Foster Care File, 6-month periods (FY2010A - 2016B) [Dataset]. National Data Archive on Child Abuse and Neglect. <a href="https://doi.org/10.34681/STYD-Z974">https://doi.org/10.34681/STYD-Z974</a>
          </li>
        </ul>
    </div>
  </div>
  <div class="container-about--section">
    <button class="section-header" @click="expandAccordian">
      NCANDS Maltreatment Reports and Recurrence
    </button>
    <div class="section-content">
      <div class="subsection">
      <div class="subsection-description">
      <p>Data from the National Child Abuse and Neglect Data Systems (NCANDS) Child File dataset for federal fiscal years (FFY) 2015-2019
        are reported separately at the county- and state-levels and presented in aggregate.
        Refer to the National Data Archive on Child Abuse and Neglect (NDACAN) for more information on these data
        (see www.ndacan.cornell.edu )*
      </p>
      <div class="subsection-variables">
          <p>
            <b>Average annual children with maltreatment reports per 1k, under age 5<sup>§</sup>. </b> Children are
            counted if they had at least one maltreatment report < 5 years of age during the time period,
              regardless of the outcome of the maltreatment investigation (e.g., maltreatment report being substantiated
              or indicated). The denominator, population of children under age 5, is from the American Community Survey,
              2015-2019.
            </p>
            <ul>
              <li>Range of rates per 1,000 children across U.S. Counties: 3.6 – 438.6</li>
              <li>Range of rates per 1,000 children across U.S. States: 11.6 – 161.2</li>
            </ul>
            <table>
              <tr>
                <td>
                  <ul class="clean-list">
                    <li>In scenario maps, we categorize the county-level data into three quantiles, labeled low, medium, and high. These categories correspond to the following values:</li>
                    <ul class="clean-list">
                      <li>Low: 3.6 – 35.6</li>
                      <li>Medium: 35.7 – 62.4</li>
                      <li>High: 62.5 – 438.6</li>
                    </ul>
                    <li>In scenario maps, we categorize the state-level data into three quantiles, labeled low, medium, and high. These categories correspond to the following values:</li>
                    <ul class="clean-list">
                      <li>Low: 11.6 – 34.0</li>
                      <li>Medium: 34.1 – 48.3</li>
                      <li>High: 48.4 – 161.2</li>
                    </ul>
                  </ul>
                </td>
              </tr>
            </table>
            <p>
              <b>Rate of substantiated incident maltreatment reports, under age 5<sup>§</sup></b> Reports are counted if it is the first 
                (incident) report for a child < 5 years of age during the time period. 
                The report can either be substantiated or indicated. The count includes children who died with 
                confirmation of death as a result of child abuse and neglect. The denominator, population of children under 
                age 5, is from the American Community Survey, 2015-2019. 
            </p>
            <ul class="clean-list">
              <li>Range of rates per 1,000 children across U.S. Counties: 0.3 – 456.9</li>
              <li>Range of rates per 1,000 children across U.S. States: 1.6 – 25.5</li>
            </ul>
            <p>
              <b>Rate of children with recurrent maltreatment reports within a 12-month period, under age 5<sup>§</sup></b> The number of children 
              < 5 years of age with recurrent reports per FFY are children with substantiated or indicated
              maltreatment reports that occur within 12 months of a previous substantiated or indicated report. To be included
              in the count, the record should cover at least 12 months. Recurrence counts are attributed to the
              FFY in which the index report falls (e.g., a child was reported in December 2016 and the maltreatment
              report was substantiated. In 2017, there were 2 substantiated maltreatment reports in February and August.
              This indicator counts under the FFY 2016 that the child had at least one subsequent substantiated maltreatment
              report, corresponding to the substantiated report in February 2017). Children who died with confirmation
              of death as a result of child abuse and neglect within 12 months after the index report are counted as
              recurrent. The denominator, population of children under age 5, is from the American
              Community Survey, 2015-2019.
            </p>
            <ul class="clean-list">
              <li>Range of rates per 1,000 children across U.S. Counties: 0.0 – 22.0</li>
              <li>Range of rates per 1,000 children across U.S. States: 0.0 – 5.7</li>
            </ul>
            <p>
              <b>Average annual children with maltreatment reports per 1k, ages 5-17<sup>§</sup></b> Children 
              are counted if they had at least one maltreatment report 5-17 years of age during the time period, 
              regardless of the outcome of the maltreatment investigation (e.g., maltreatment report being substantiated 
              or indicated). The denominator, population of children aged 5-17, is from the American Community Survey, 
              2015-2019. 
            </p>
            <ul class="clean-list">
              <li>Range of rates per 1,000 children across U.S. Counties: 7.8 – 894.5</li>
              <li>Range of rates per 1,000 children across U.S. States: 23.0 – 309.6</li>
            </ul>
              <p>
                <b>Rate of substantiated incident maltreatment reports, ages 5-17<sup>§</sup></b> 
                Reports are counted if it is the first (incident) report for a child 5-17 
                  years of age during the time period. The report can either be substantiated or indicated. 
                  The count includes children who died with confirmation of death as a result of child abuse and neglect. 
                  The denominator, population of children aged 5-17, is from the American Community Survey, 2015-2019. 
              </p>
              <ul class="clean-list">
                <li>Range of rates per 1,000 children across U.S. Counties: 0.0 – 26.6</li>
                <li>Range of rates per 1,000 children across U.S. States: 0.9 – 9.7</li>
              </ul>
              <p>
                <b>Rate of children with recurrent maltreatment reports within a 12-month period, ages 5-17<sup>§</sup></b> 
                The number of children aged 5-17 years with recurrent reports per FFY  are children with 
                substantiated or indicated maltreatment reports that occur within 12 months of a previous 
                substantiated or indicated report. To be included in the count, the record should cover at least 12 months.  Recurrence counts are attributed to the FFY in which the index report falls (e.g., a child was reported in December 2016 and the maltreatment report was substantiated. In 2017, there were 2 substantiated maltreatment reports in February and August. This indicator counts under the FFY 2016 that the child had at least one subsequent substantiated maltreatment report, corresponding to the substantiated report in February 2017). Children who died with confirmation of death as a result of child abuse and neglect within 12 months after the index report are counted as recurrent. The denominator, population of children aged 5-17, is from the American Community Survey, 2015-2019.
              </p>
              <ul class="clean-list">
                <li>Range of rates per 1,000 children across U.S. Counties: 0.0 – 13.6</li>
                <li>Range of rates per 1,000 children across U.S. States: 0.1 – 3.0</li>
              </ul>
              <p>
                <sup>§</sup> Exclusions Criteria (following CFSR-3): The report occurring after the index report must not
                  have the same incident date and should not have a report date within 14 days of the index report.
                  Alternative response, absence of disposition, unsubstantiated reports are excluded from incident
                  maltreatment reports and recurrence. Victims age 18 and older excluded. Substantiated maltreatment
                  before child is born is not counted.
              </p>
              <p>
                <sup>¥</sup> The analyses presented in this publication were based on data from the National
                Child Abuse and Neglect Data System (NCANDS) Child File, FFY 2015-2019. These data were
                provided by the National Data Archive on Child Abuse and Neglect at Cornell University and
                have been used with permission. The data were originally collected under the auspices of the
                Children's Bureau. Funding was provided by the Children's Bureau, Administration on
                Children, Youth and Families, Administration for Children and Families, U.S. Department of
                Health and Human Services. The collector of the original data, the funding agency, NDACAN,
                Cornell University, and the agents or employees of these institutions bear no responsibility
                for the analyses or interpretations presented here. The information and opinions expressed
                reflect solely the opinions of the authors.<br />
                Counties without data may have less than 1,000 children and the data cannot be provided by
                NDACAN, or the child welfare agency is located at a neighboring county and all cases are
                counted under the lead agency.
              </p>
              <p>The bibliographic citation for this data collection is: </p>
              <ul class="bibliography">
                <li>Children's Bureau, Administration On Children, Youth And Families, Administration For Children And Families, U. S. Department Of Health And Human Services (2020). National Child Abuse and Neglect Data System (NCANDS) Child File, FFY 2020 [Dataset]. National Data Archive on Child Abuse and Neglect.<a href="https://doi.org/10.34681/qwax-vb30">https://doi.org/10.34681/qwax-vb30</a>
                </li>
                <li>Children's Bureau, Administration On Children, Youth And Families, Administration For Children And Families, U. S. Department Of Health And Human Services (2019). National Child Abuse and Neglect Data System (NCANDS) Child File, FFY 2019, Version 2 [Dataset]. National Data Archive on Child Abuse and Neglect <a href="https://doi.org/10.34681/7gy0-e516">https://doi.org/10.34681/7gy0-e516</a>
                </li>
                <li>Children's Bureau, Administration On Children, Youth And Families, Administration For Children And Families, U. S. Department Of Health And Human Services (2019). National Child Abuse and Neglect Data System (NCANDS) Child File, FFY 2017, Version 3 [Dataset]. National Data Archive on Child Abuse and Neglect. <a href="https://doi.org/10.34681/04hy-mc45">https://doi.org/10.34681/04hy-mc45</a>
                </li>
                <li>Children's Bureau, Administration On Children, Youth And Families, Administration For Children And Families, U. S. Department Of Health And Human Services (2019). National Child Abuse and Neglect Data System (NCANDS) Child File, FFY 2017, Version 3 [Dataset]. National Data Archive on Child Abuse and Neglect. <a href="https://doi.org/10.34681/TMRZ-JN03">https://doi.org/10.34681/TMRZ-JN03</a>
                </li>
                <li>Children's Bureau, Administration On Children, Youth And Families, Administration For Children And Families, U. S. Department Of Health And Human Services (2018). National Child Abuse and Neglect Data System (NCANDS) Child File, FFY 2016, Version 3 [Dataset]. National Data Archive on Child Abuse and Neglect. <a href="https://doi.org/10.34681/DAG6-8J36">https://doi.org/10.34681/DAG6-8J36</a>
                </li>
                <li>Children's Bureau, Administration On Children, Youth And Families, Administration For Children And Families, U. S. Department Of Health And Human Services (2017). National Child Abuse and Neglect Data System (NCANDS) Child File, FFY 2015, Version 4 [Dataset]. National Data Archive on Child Abuse and Neglect. <a href="https://doi.org/10.34681/SZHC-3V41">https://doi.org/10.34681/SZHC-3V41</a>
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      <div class="container-about--section">
        <hr>
        <button class="section-header" @click="expandAccordian">
          RTI Rarity Risk Scores
        </button>
        <div class="section-content">
          <div class="subsection">
            <!-- <div class="subsection-description"> -->
                <!-- <p>
                  Child welfare information was curated by the Child and Family Wellbeing project, 
                  funded by the Centers for Disease Control and Prevention of the U.S. Department of Health and Human 
                  Services (HHS) (R01CE003098). These layers allow users to visualize specific policies relevant to child 
                  welfare. The contents are those of the author(s) and do not necessarily represent the official views of, 
                  nor an endorsement, by CDC/HHS, or the U.S. Government.
                </p> -->
              <!-- <table>
              <tr>
                <td>
                  <ul class="clean-list">
                    <li>In scenario maps, we categorize the Census tract-level LSI data into three quantiles, labeled low, medium, and high. These categories correspond to the following values:</li>
                    <ul class="clean-list">
                      <li>Low: 0 – 0.33</li>
                      <li>Medium: 0.34 – 0.66</li>
                      <li>High: 0.67 – 1.00</li>
                    </ul>
                  </ul>
                </td>
              </tr>
            </table> -->
        <!-- </div> -->
        </div>
        <div class="subsection">
            <div class="subsection-description">
              <p>
                <b>Local Index in Life Expectancy (LI-LE)</b>  is a ranked percentile score for every Census tract in the US (50 states plus DC) that represents a neighborhood's relative risk of lower LE. The higher the score, the lower the predicted life expectancy and vice versa. The risk scores draw on RTI Rarity's large data library and are generated using "artificially intelligent" data science methods. 
              </p>
              <ul class="lsi-based-list">
                <li>In terms of life expectancy at birth, our LI-LE scores explain a stunning 67% of the variance. In other words, the LI scores account for two-thirds of the difference between the neighborhoods with the highest and lowest life expectancies in the US. </li>

                <li>In our pilot phase, the <a href="https://innovation.cms.gov/innovation-models/state-innovations-round-two">CMS State Innovation Models Round 2 Evaluation project</a> used LI-LE scores as a covariate in models testing Ohio's Comprehensive Primary Care demonstration. Within an all-Medicaid population, in both bivariate and fully adjusted triple-difference models, LI-LE scores were significantly associated with a number of health outcomes that are expensive, preventable, and potentially modifiable through policy mechanisms. </li>
              </ul>
            </div>
        </div>
        <div class="subsection">
              <div class="subsection-description">
                <hr>
                  <p>
                    <b>Local Index in cancer mortality (LI-Ca)</b> scores are percentile-ranked scores for each Census tract in the US (50 states plus DC) that represents a neighborhood's relative risk of higher age-adjusted cancer mortality rates. Higher scores indicate higher predicted cancer mortality rates and vice versa. The scores draw on RTI Rarity's large data library and are generated using “artificially intelligent” data science methods. 
                  </p>
                  <ul class="lsi-based-list">
                    <li>Across the entire US, the LI-Ca scores explained an incredible 98% of the variance in local cancer mortality… with just 42 variables! See <a href="https://www.themedicalcareblog.com/social-drivers-cancer-mortality/">https://www.themedicalcareblog.com/social-drivers-cancer-mortality/</a> and <a href="https://www.themedicalcareblog.com/social-drivers-cancer-mortality-2/">https://www.themedicalcareblog.com/social-drivers-cancer-mortality-2/</a> for more information about these scores.</li>
                  </ul>
            </div>
          </div>
          <div class="subsection">
              <div class="subsection-description">
                <hr>
                  <p>
                    <b>Local Index in drug overdose (LI-DO) </b> scores are percentile-ranked scores for each Census tract in the US (50 states plus DC) that represents a neighborhood's relative risk of higher drug overdose mortality rates. Higher scores indicate higher predicted drug overdose mortality rates and vice versa. The scores draw on RTI Rarity's large data library and are generated using “artificially intelligent” data science methods.
                  </p>
                  <ul class="lsi-based-list">
                    <li>We piloted the development of these scores using just 30 measures in 4 states participating in the HEALing Communities Study, funded by NIDA and SAMHSA</li>
                      <li class="sub-bullet">The manuscript describing the pilot work, titled Development and Validation of a Community-Level Social Determinants of Health Index for Opioid Use Disorder in the HEALing Communities Study, is currently published in the <a href="https://www.jsatjournal.com/article/S2949-8759(24)00164-4/fulltext">Journal of substance use and addiction treatment.</a></li>
                    <li>Our national LI-DO model includes 40 measures and explains 99% of the variance in drug overdose mortality rates across 67,079 Census tracts. </li>
      
                  </ul>
            </div>
        </div>
        <div class="subsection">
              <div class="subsection-description">
                <hr>
                  <p>
                    <b>Local Index in sexual and reproductive health (LI-SRH)</b> scores are percentile-ranked scores for each Census tract in the US (50 states plus DC) that represents a neighborhood's relative risk of adverse SRH outcomes. Higher scores indicate higher predicted risk of adverse SRH outcomes and vice versa. The scores draw on RTI Rarity's large data library and are generated using “artificially intelligent” data science methods. 
                  </p>
                  <ul class="lsi-based-list">
                    <li>The composite SRH outcome was based on the normalized mean of 5 measures of adverse SRH outcomes: chlamydia incidence, gonorrhea incidence, percentage of births with low birthweight, percentage of women who were teen mothers in the 1990s, and teen birthrates. </li>
                    <li>Our national model includes 42 measures and explains 81% of the variance in the composite SRH outcome. </li>
                    <li>Our work on the LI-SRH to date was accepted for both poster and podium presentations at the 2023 Annual Research Meeting of AcademyHealth, June 2023: </li>
                      <li class="sub-bullet"><a href="https://academyhealth.confex.com/academyhealth/2023arm/meetingapp.cgi/Paper/58939">Using Areal Data on Social Determinants to Predict Sexual and Reproductive Health Outcomes</a></li>
                      <li class="sub-bullet"><a href="https://academyhealth.confex.com/academyhealth/2023arm/meetingapp.cgi/Paper/60479">Using a Local Index Adapted for Sexual and Reproductive Health to Study Access to Federally Funded Family Planning Services </a></li>
                    
                  </ul>
                  
            </div>
        </div>
      </div>
      <hr>
      </div>
      <div class="container-about--section">
        <button class="section-header" @click="expandAccordian">
          Community Information
        </button>
        <div class="section-content">
          <div class="subsection">
            <div class="subsection-description">
                <p>
                  <b>Urban-Rural Classification </b> identifies counties as either urban, suburban, or rural based on <a
                    href="https://www.ers.usda.gov/data-products/rural-urban-continuum-codes.aspx">Rural-Urban Continuum
                    Codes (RUCC), 2013</a>. The NCANDS Child File assigns each U.S. county a RUCC value, ranging between 1-9 (variable name RU13). Here, we collapse the 9 categories into 3 meaningful and recognizable categories. 
                </p>
                <ul class="clean-list">
                  <li><b>Urban</b> refers to counties in metro areas of 1 million plus residents (RUCC 1)</li>

                  <li><b>Suburban</b> refers to counties in metro areas of fewer than 1 million residents as well as urban areas that are adjacent to metro areas and have populations of at least 20,000 residents (RUCC 2-4) </li>

                  <li><b>Rural</b> refers to completely rural areas, counties with less than 20,000 residents in urban areas, or counties that are not in or adjacent to metro areas (RUCC 5-9).</li>
                </ul>
                <hr>
                <p>
                  <b>Social Capital Index</b>, developed by the Joint Economic Committee of the U.S. Senate, combines many indicators related to family structure and stability, family interaction and investment, civil society, trust and confidence in institutions, community cohesion, institutions, volunteerism, and social organization. Using statistical techniques that standardize and weight variables, they created a score for each County that represent the overall level of <a
                  href="https://web.archive.org/web/20210910182928/https:/www.jec.senate.gov/public/index.cfm/republicans/2018/4/the-geography-of-social-capital-in-america">social
                  capital</a>. The index ranges from -4.5 [least social capital] to 3.0 [most social capital].
                </p>
                <hr>
                <p>
                  <b>Childhood Opportunity Index (COI) 2.0,</b> developed by <a
                    href="https://www.diversitydatakids.org/child-opportunity-index">DiversityDataKids</a>, measures the quality of resources and conditions that matter for children to develop in a healthy way in the neighborhoods where they live. Here, we present the overall child opportunity index, allowing us to compare the level of opportunity that neighborhoods provide for children across the United States. The score ranges from 0 (least opportunity) to 100 (most opportunity). The DiversityDataKids research team collected 29 census tract-level indicators from 2015 that measure neighborhood-based opportunities for children including, but not limited to, access and quality of early childhood education, high-quality schools, green space, healthy food, toxin-free environments, socioeconomic resources and more. Using statistical techniques that standardize and weight variables, the COI created a score for each census tract that represents the overall level of child opportunity. 
                </p>
        </div>
      </div>
    </div>
    <hr>
    <div class="container-about--section">
        <button class="section-header" @click="expandAccordian">
          Individual & Family Services
        </button>
      <div class="section-content">
        <div class="subsection">
          <p>
            <b>Title X</b> is our nation’s safety net for family planning and related preventive health care. It is the only federal grant program dedicated solely to providing these services. Title X services are available to everyone, but the program prioritizes access for low-income individuals by means of a sliding fee scale that provides family planning services at no charge to those with incomes at or below poverty, and at a reduced fee based on income for those with incomes between 101% and 250% of poverty. The program served 1.7 million clients in 2021. Data on the locations of Title X-funded clinics were obtained from the October 2022 Title X Clinic Locator Database for the Right Place, Right Time study.  
          </p>
        </div>
        <div class="subsection">
          <div class="subsection-description">
            <p>
              These layers reflect a range of services and businesses related to mental health, substance use, and parenting/caregiving services. The data are presented at the point-level for each location identified as an individual/family social service. This data was obtained by gathering a subset of business data with  <a href="https://siccode.com/">Service Industry Codes (SIC)</a> from ArcGIS Business Analyst.
              For more information on the data see: <a
                href="https://doc.arcgis.com/en/esri-demographics/latest/regional-data/business.htm">ArcGIS Business
                Analyst</a>.
            </p>
            <hr>
          </div>
          <p class="subsection-title">
            <b>Mental Health Services </b>
          </p>
          <div class="subsection-list">
            <div>
              <ul class="clean-list">
                <li><b>SIC</b></li>
                <li>804931</li>
                <li>832215</li>
                <li>832283</li>
                <li>839915</li>
                <li>801113</li>
                <li>801106</li>
                <li>806301</li>
                <li>806302</li>
                <li>806303</li>
                <li>806304</li>
                <li>806305</li>
                <li>806398</li>
                <li>809305</li>
                <li>832201</li>
                <li>832202</li>
                <li>832204</li>
                <li>832205</li>
                <li>832212</li>
                <li>832217</li>
                <li>832223</li>
                <li>832227</li>
                <li>832228</li>
              </ul>
            </div>
            <div>
              <ul class="clean-list">
                <li><b>SIC Description</b></li>
                <li>EATING DISORDERS INFO & TREATMENT CTRS</li>
                <li>MARRIAGE & FAMILY COUNSELORS</li>
                <li>COUNSELORS-COUPLES</li>
                <li>GAY & LESBIAN ORGANIZATIONS</li>
                <li>PSYCHIATRY-ADULT CHILD & ADOLESCENT</li>
                <li>PSYCHOANALYSTS</li>
                <li>MENTAL HEALTH SERVICES</li>
                <li>SANITARIUMS</li>
                <li>PSYCHIATRIC TREATMENT FACILITIES</li>
                <li>ATTENTION DEFICIT DISORDER SERVICES</li>
                <li>ANGER MANAGEMENT</li>
                <li>PSYCHIATRIC HOSPITALS</li>
                <li>MENTAL HEALTH CLINICS</li>
                <li>COUNSELING SERVICES</li>
                <li>CHILD GUIDANCE</li>
                <li>COUNSELORS-LICENSED PROFESSIONAL</li>
                <li>AUTISM COUNSELING & TREATMENT</li>
                <li>HUMAN RELATIONS COUNSELORS</li>
                <li>PSYCHOLOGIST INFORMATION/REFERRAL SVCS</li>
                <li>STRESS MANAGEMENT SERVICES</li>
                <li>MENTAL HEALTH COUNSELORS</li>
                <li>REFERRAL SERVICES</li>
              </ul>
            </div>
            <div>
              <ul class="clean-list">
                <li><b>SIC</b></li>
                <li>832231</li>
                <li>832233</li>
                <li>832246</li>
                <li>832249</li>
                <li>832263</li>
                <li>832266</li>
                <li>832271</li>
                <li>832282</li>
                <li>832284</li>
                <li>832285</li>
                <li>832288</li>
                <li>832289</li>
                <li>804911</li>
                <li>804921</li>
                <li>804922</li>
                <li>804932</li>
                <li>804945</li>
                <li>804950</li>
                <li>804970</li>
                <li>804980</li>
                <li>804998</li>
                <li>864118</li>
              </ul>
            </div>
            <div>
              <ul class="clean-list">
                <li><b>SIC Description</b></li>
                <li>SELF-ESTEEM PROGRAMS</li>
                <li>SUPPORT GROUPS</li>
                <li>SELF IMPRVMNT/MOTIVATION-TRAINING MTRLS</li>
                <li>LEARNING DISABILITY COUNSELING</li>
                <li>PET LOSS COUNSELING SERVICES</li>
                <li>COUNSELORS-WORKPLACE RELATIONS</li>
                <li>SELF HELP GROUPS</li>
                <li>COUNSELORS</li>
                <li>COUNSELORS-HOLISTIC</li>
                <li>AUTISM INFORMATION & REFERRAL SVC</li>
                <li>COUNSELING-TRANSFORMATION SERVICES</li>
                <li>COUNSELORS-ANGER CONTROL</li>
                <li>OCCUPATIONAL THERAPISTS</li>
                <li>PSYCHIATRIC SOCIAL WORKERS</li>
                <li>PSYCHOLOGISTS</li>
                <li>THERAPISTS-CORRECTIVE</li>
                <li>PSYCHOLOGISTS EDUCATIONAL</li>
                <li>THERAPY-GROUP</li>
                <li>PSYCHOSOCIAL THERAPISTS</li>
                <li>PLAY THERAPIST</li>
                <li>THERAPY</li>
                <li>TEEN CLUBS</li>

              </ul>
            </div>
          </div>
          <hr>
          <ul class="subsection-title clean-list-header">
            <li><b>Substance Use Services</b></li>
            <li><b>Parenting Services</b></li>
          </ul>
          <div class="subsection-list">
            <div>
              <ul class="clean-list">
                <li><b>SIC</b></li>
                <li>804931</li>
                <li>832215</li>
                <li>832283</li>
                <li>839915</li>
                <li>801113</li>
                <li>801106</li>
                <li>806301</li>
                <li>806302</li>
              </ul>
            </div>
            <div>
              <ul class="clean-list">
                <li><b>SIC Description</b></li>
                <li>Homeless Shelters</li>
                <li>Crisis Centers</li>
                <li>Social Workers-Clinical</li>
                <li>Learning Disability Counseling</li>
                <li>Substance Abuse Centers</li>
                <li>Employee Assistance Programs</li>
                <li>Government-Individual/family Social Services</li>
                <li>Soup Kitchens</li>
              </ul>
            </div>

            <div>
              <ul class="clean-list-table">
                <li><b>SIC</b></li>
                <li>804931</li>
                <li>804931</li>
                <li>832215</li>
              </ul>
            </div>
            <div>
              <ul class="clean-list-table">
                <li><b>SIC Description</b></li>
                <li>CHILD GUIDANCE</li>
                <li>PLAY THERAPIST</li>
                <li>CHILD DEVELOPMENT-PARENT/CHILD EDUCATION</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="container-about--section">
      <button class="section-header" @click="expandAccordian">
        Child Welfare Policy
      </button>
      <div class="section-content">
        <div class="subsection">
          <div class="subsection-description">
            <div class="subsection-variables">
              <p>
                Child welfare information was curated by the Child and Family Wellbeing project, funded by the Centers for Disease Control and Prevention of the U.S. Department of Health and Human Services (HHS) (R01CE003098). These layers allow users to visualize specific policies relevant to child welfare. The contents are those of the author(s) and do not necessarily represent the official views of, nor an endorsement, by CDC/HHS, or the U.S. Government. 
              </p>
              <hr>
              <p>
                <b>Family First Legislation - Status of Title IV-E Prevention Program Five-Year Plans, 2020</b> data classifies each state as having submitted, not-submitted, or awaiting approval of their Status of Title IV-E Prevention Program Five-Year Plans as of July 2022 (see <a href="https://www.acf.hhs.gov/cb/data/status-submitted-title-iv-e-prevention-program-five-year-plans#:~:text=Status%20of%20Submitted%20Title%20IV-E%20Prevention%20Program%20Five-Year,%20%20Submitted%20%2060%20more%20rows%20">Status of Submitted Title IV-E Prevention Program Five-Year Plans | The Administration for Children and Families (hhs.gov)</a>). The Family First Prevention Services Act (FFPSA), enacted as part of Public Law (P.L.) 115—123, authorized new optional title IV-E of the Social Security Act funding for time-limited prevention services for mental health, substance abuse, and in-home parent skill-based programs for children or youth who are candidates for foster care, pregnant or parenting youth in foster care, and the parents or kin caregivers of those children and youth. As a condition of the receipt of Prevention Services and Program funds under title IV-E, states must submit and receive approval from the Administration for Children and Families/Children's Bureau for their Title IV-E Prevention Program Five-Year Plan. 
              </p>
              <hr>
              <p>
                <b>The Safe Babies, a program of ZERO TO THREE</b>, works to prevent the need for babies and toddlers to be removed from their families and enter out-of-home care. Safe Babies works with communities to build services and supports families need so they can experience healing and less involvement with courts and the child welfare system. When parents and caregivers with young children are surrounded with the resources and support that they need to nurture positive relationships, it leads to communities that are stronger, healthier, and safer for babies, toddlers and their families. Learn more about Safe Babies <a href=" https://www.zerotothree.org/our-work/safebabies/">here.</a> 
              </p>
              <p>
                The <b>Safe Babies Sites</b> across the United States are categorized into four distinct statuses based on their stage in implementing the Safe Babies Court Team approach. These statuses are designed to reflect the level of engagement and development each site has achieved towards fully adopting and sustaining the approach. Here's what each status signifies:
              </p>
              <ul class="clean-list">
                <li><b>Active Sites:</b>  These sites have been effectively serving children and families for a minimum of four months. They have established procedures and criteria for identifying and referring new cases and have an active community team in place. The active status reflects a robust and ongoing commitment to the Safe Babies Court Team approach.
                </li>
  
                <li><b>Legacy Sites:</b> Legacy Sites are those that have previously received support from the National Resource Center and have continued to implement the Safe Babies Court Team approach independently. These sites have demonstrated sustainability and autonomy in their operations and are denoted in green to highlight their established legacy.
                </li>
  
                <li><b>Installing Sites:</b>  Identified by having a Community Coordinator either in place or in the process of being hired. These sites have secured the commitment of child welfare leadership and a judge or court to the implementation of the Safe Babies approach. The “installing” status indicates that foundational steps towards full implementation are actively being taken. 
                </li>
  
                <li><b>Exploring Sites:</b> Sites with this status are in the initial phases of considering the Safe Babies approach. They have reached out to the Infant-Toddler Court Program National Resource Center for guidance or technical assistance. Exploring signifies a preliminary interest in adoption and understanding of the approach, with steps being taken towards more formal engagement. 
                </li>
                </ul>
              <hr>
              <p>
                <b>Changes in Child Maltreatment Definitions</b>  data describes changes in child abuse and neglect (CAN) policy definitions from 2016-2019. These retrospective policy data were developed under the expert guidance of <a href="https://csw.osu.edu/about/faculty-staff/faculty-directory/johnson-motoyama-michelle-ph-d/">Dr. Michelle Johnson-Motoyama</a>, Professor of Social Work with The Ohio State University. State-level policies were coded following the methodology developed by the Temple University's Center for Public Health Law Research (Law Atlas project). This analysis was conducted through a comparison of the April 2016 and March 2019 reports from the Child Welfare Information Gateway's State Statutes series “Definitions of Child Abuse and Neglect.” At the State level, child abuse and neglect may be defined in both civil and criminal statutes. The changes to definitions presented are civil definitions that determine the grounds for intervention by State child protective agencies. 
              </p>
              <ul class="clean-list">
              <li><b>No Changes:</b>  No changes were made to child abuse and neglect policies between 2016-2019.</li>

              <li><b>Broadened Changes:</b> Changes to child abuse and neglect policies between 2016-2019 resulted in broadened child abuse and neglect definition(s) through the inclusions of new elements (e.g., increasing the age at which children cannot be left alone at home or walk to school or to a park unsupervised, that varies from a minimum age of 8 years in Maryland to 14 years in Illinois).</li>

              <li><b>Narrowed Changes:</b>  Changes to child abuse and neglect policies between 2016-2019 resulted in narrowed child abuse and neglect definition(s) through the exclusions of elements (e.g., in 2022 Colorado narrowed the definition of child neglect with the “Free-Range Parenting” law that give children more freedom to roam unsupervised without exposing parents to child neglect investigations).</li>

              <li><b>Mixed Changes:</b> Changes to child abuse and neglect policies between 2016-2019 resulted in CAN definition(s) that were both broadened and narrowed.</li>
              </ul>
              <p>In addition to categorizing how the state-level child abuse and neglect definition(s) changed between 2016-2019, this data layer also provides information on the <b>type of change(s)</b> that occurred and whether that type of change resulted in a broadened, narrowed, mixed, or neither broadened nor narrowed change to child abuse and neglect definitions. States recognize different types of abuse in their definitions as well as standards for making reports, definitions of individuals that can be reported, and exceptions that exempt certain acts or omissions from statutory child abuse and neglect definitions. Definitions typically include, but are not limited to, physical abuse, neglect, sexual abuse, and emotional abuse. Some states include additional definitions in their state statutes for parental substance abuse, domestic violence, human trafficking, abandonment, and other forms of child abuse and neglect.</p>
              <ul class="clean-list">
                <li><b>Maltreatment Type Added:</b> The state added a new type of child abuse and neglect to their definition.</li>
                <li><b>Maltreatment Type Deleted:</b> The state removed a type of child abuse and neglect from their definition.</li>
                <li><b>Specific Maltreatment Type Redefined:</b> The state redefined a specific type of child abuse and neglect.</li>
                <li><b>Standard of Reporting:</b> Typically, a child abuse and neglect report must be made when an individual knows or has reasonable cause to believe or suspect that a child has been subjected to abuse or neglect. These standards guide mandatory reporters in deciding whether to make a report to child protective services and may change over time.</li>
                <li><b>Persons Responsible for the Child:</b> State statutes provide specific definitions of persons who can be reported to child protective services as perpetrators of abuse or neglect. Changes to definitions may add or delete relationships to the child.</li>
                <li><b>Exceptions:</b> A number of states provide exceptions in their reporting laws that exempt certain acts or omissions from their statutory definitions of child abuse and neglect such as physical discipline or religious beliefs. Exceptions may be added or deleted.</li>
              </ul>
              <p>This analysis was supported by the Centers for Disease Control and Prevention of the U.S. Department of Health and Human Services (HHS) (R01CE003098). The contents are those of the author(s) and do not necessarily represent the official views of, nor an endorsement, by CDC/HHS, or the U.S. Government.</p>
            </div>
        </div>
      </div>
      <div class="subsection">
        <div class="subsection-description">
          <hr>
          <p>Retrospective polices from the <b>State Child Abuse and Neglect (SCAN) Policies Database.</b> <a href="https://www.scanpoliciesdatabase.com/">The SCAN Policies Database</a> includes 2019 data on select definitions and policies related to child maltreatment for 50 states, the District of Columbia, and Puerto Rico.</p>
          <div class="subsection-variables">
            <ul class="clean-list">
              <li><b>State operates under a legal consent decree, 2019.</b>State child welfare system (CWS) operates under a legal consent decree or other court-ordered monitoring with the goal of reforming the CWS and improving conditions for children and families. Policy values include: (a) No, (b) Yes. Data are derived from SCAN Policies Database protocol Number C2 (see <a href="https://www.casey.org/consent-decree-summary/"> Child Welfare Consent Decrees - Casey Family Programs</a>for detailed information about each state's consent decree).</li>
              <li><b>State CAN definition includes exposure to domestic violence, 2019.</b> Definition of child maltreatment includes exposure to domestic violence, intimate partner violence, or household violence. Policy values include: (a) No, (b) Yes. Data are derived from SCAN policies Database protocol number D1_23.</li>
              <li><b>Training required for mandated reporters, 2019.</b> Training is required for mandatory reporters of child abuse and neglect. Policy values include: (a) No, (b) Yes, all mandated reporters, (c) Yes, some mandated reporters, (d) Unknown. Data are derived from SCAN policies Database protocol number R4 (see <a href="https://www.childwelfare.gov/organizations/?CWIGFunctionsaction=rols:main.dspList&rolType=Custom&RS_ID=162">State Training Resources for Mandatory Reporters of Child Abuse and Neglect - Child Welfare Information Gateway</a> for detailed information about required training for mandatory reporters).</li>
              <li><b>State implements differential response, 2019.</b> State has alternative or differential response, a system reform that provides multiple pathways to respond to child maltreatment reports (e.g., instead of taking the path of a maltreatment investigation by child protective services, differential response may assess family needs and connect them with services). Policy values include: (a) No, (b) Yes, implemented statewide, (c) Yes, implemented in specific counties or regions. Data are derived from SCAN policies Database protocol number W1 (see <a href="https://www.childwelfare.gov/pubPDFs/differential_response.pdf">Differential Response: A Primer for Child Welfare Professionals</a> for detailed information about differential response).</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <!-- <div class="container-about--section">
      <button class="section-header" @click="expandAccordian">
            SCAN Policies
        </button>
        <div class="section-content">
        <div class="subsection">
          <div class="subsection-description">
            <p>Retrospective polices from the <b>State Child Abuse and Neglect (SCAN) Policies Database.</b> <a href="https://www.scanpoliciesdatabase.com/">The SCAN Policies Database</a> includes 2019 data on select definitions and policies related to child maltreatment for 50 states, the District of Columbia, and Puerto Rico.</p>
            <div class="subsection-variables">
              <ul class="clean-list">
                <li><b>State operates under a legal consent decree, 2019.</b>State child welfare system (CWS) operates under a legal consent decree or other court-ordered monitoring with the goal of reforming the CWS and improving conditions for children and families. Policy values include: (a) No, (b) Yes. Data are derived from SCAN Policies Database protocol Number C2 (see <a href="https://www.casey.org/consent-decree-summary/"> Child Welfare Consent Decrees - Casey Family Programs</a>for detailed information about each state's consent decree).</li>
                <li><b>State CAN definition includes exposure to domestic violence, 2019.</b> Definition of child maltreatment includes exposure to domestic violence, intimate partner violence, or household violence. Policy values include: (a) No, (b) Yes. Data are derived from SCAN policies Database protocol number D1_23.</li>
                <li><b>Training required for mandated reporters, 2019.</b> Training is required for mandatory reporters of child abuse and neglect. Policy values include: (a) No, (b) Yes, all mandated reporters, (c) Yes, some mandated reporters, (d) Unknown. Data are derived from SCAN policies Database protocol number R4 (see <a href="https://www.childwelfare.gov/organizations/?CWIGFunctionsaction=rols:main.dspList&rolType=Custom&RS_ID=162">State Training Resources for Mandatory Reporters of Child Abuse and Neglect - Child Welfare Information Gateway</a> for detailed information about required training for mandatory reporters).</li>
                <li><b>State implements differential response, 2019.</b> State has alternative or differential response, a system reform that provides multiple pathways to respond to child maltreatment reports (e.g., instead of taking the path of a maltreatment investigation by child protective services, differential response may assess family needs and connect them with services). Policy values include: (a) No, (b) Yes, implemented statewide, (c) Yes, implemented in specific counties or regions. Data are derived from SCAN policies Database protocol number W1 (see <a href="https://www.childwelfare.gov/pubPDFs/differential_response.pdf">Differential Response: A Primer for Child Welfare Professionals</a> for detailed information about differential response).</li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="container-about--section">
        <button class="section-header" @click="expandAccordian">
          Terms of Use
        </button>
        <div class="section-content">
          <div class="subsection flex-center">
            <!-- <a href="/files/Terms of Use.pdf" target="_blank">View the Terms of Use Policy</a> -->
            <b-button role="link" @click="openInNewTab('/files/Rarity-Terms-of-Use.pdf')" variant="primary">View the Terms of Use Policy</b-button>
          </div>
        </div>
      </div>
      <hr>
  </div>
</div>
</template>

<script>
export default {
name: "Data",
data: function () {
  return {};
},
methods: {
  expandAccordian(e) {
    e.target.classList.toggle("active");
    const sectionContent = e.target.nextElementSibling;
    if (sectionContent.style.display === "block") {
      sectionContent.style.display = "none";
    } else {
      sectionContent.style.display = "block";
    }
  },
  openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Rarity */
.banner-image {
  display: flex;
  justify-content: center;
}

.display-flex-award {
  display: inline-flex;
}
.award-image {
  margin: 0 5%;
}

.aboutImage{
width: 60%;
}

.flex-center {
  display: flex;
  justify-content: center;
}

p, em {
font-size: 16px;
letter-spacing: 0.25px;
orphans: 3;
}
.clean-list{
list-style: none;
font-size: 16px;
letter-spacing: 0.25px;
orphans: 3;
}
.clean-list-table{
align-items: center;
list-style: none;
font-size: 16px;
letter-spacing: 0.25px;
orphans: 3;
width: 50%;
}
.lsi-based-list {
list-style: circle;
font-size: 16px;
letter-spacing: 0.25px;
orphans: 3;
}
.sub-bullet {
margin-left: 5%;
list-style: disc !important;
}
.bibliography{
list-style: none;
font-size: 14px;
letter-spacing: 0.25px;
orphans: 3;
}
.clean-list-header{
padding: 2rem 0rem 0rem;
width: 90%;
list-style: none;
}
.clean-list-header li{
padding: 1rem 2rem 1rem 0rem;
max-width:50%;
list-style: none;
display: inline;
text-align: center;
font-size: 18px;
letter-spacing: 0.20px;
orphans: 3;
}
.container-about {
width: 95%;
display: flex;
flex-direction: column;
padding: 2rem 2rem 2rem 3rem;
flex-grow: 2;
}

.container-about--header {
align-self: start;
}

.container-about--content {
margin: 0rem;
}

.intro-text-body {
font-size: 1.6rem;
}

.container-about--text {
padding: 1rem 2rem 1rem 0rem;
width: 90%;
}

.section-header {
background-color: white;
color: #2c3e50;
cursor: pointer;
padding: 18px 18px 18px 0px;
width: 100%;
font-size: 28px;
font-weight: 500;
text-align: left;
display: flex;
justify-content: space-between;
align-items: center;
border: none;
outline: none;
transition: 0.4s;
}

.section-header:hover {
color: rgba(44, 62, 80, 0.75);
}

.section-header:after {
content: "\02795";
font-size: 13px;
color: #2c3e50;
float: right;
margin-left: 5px;
}

.active:after {
content: "\2796";
}

.section-content {
width: 96%;
padding-left: 1.5rem;
display: none;
overflow: hidden;
}

.subsection-variables {
padding-left: 1.5rem;
}

.subsection-list {
display: flex;
justify-content: space-evenly;
}
.subsection-title{
text-align: center;
display: flex;
justify-content: space-evenly;
}

.text-new {
color: #40668c;
}

@media screen and (max-width: 800px) {
.intro-paragraph {
  padding-left: 0;
  padding-right: 5%;
  max-width: 100%;
}

.container-about--text,
.container-about--content {
  width: 100%;
}
}
</style>
